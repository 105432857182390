<template>
  <div class="mx-4">
    <div class="bg-mask"></div>
    <div class="relative m-auto z-10">
      <div class="header-search-page mt-6">
        <a class="cursor-pointer" @click="()=> this.$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="7.41" height="12" viewBox="0 0 7.41 12">
            <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(-8.59 -6)" fill="#fff"/>
          </svg>
        </a>
        <p class="text-center">انتخاب آدرس</p>
      </div>
      <div class="search__wrapper">
        <span class="svg-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.686" height="19.729" viewBox="0 0 19.686 19.729">
            <path id="Union_1" data-name="Union 1" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(0.5 0.5)" fill="none" stroke="#ff7400" stroke-width="1"/>
          </svg>
        </span>
        <input type="text" @input="filterArea"  placeholder="منطقه مورد نظر خود را جستجو کنید">
        <span class="selectCity" @click="isShowCities = !isShowCities">{{ selectedCity }}</span>
      </div>
      <template v-if="isShowCities">
        <div class="city-search mt-4">
          <input @input="filterCity" type="text" placeholder="شهر مورد نظرتو جستجو کن">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.686" height="19.729" viewBox="0 0 19.686 19.729">
            <path id="Union_3" data-name="Union 3" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(0.5 0.5)" fill="none" stroke="#afafaf" stroke-width="1"/>
          </svg>
        </div>
        <div class="list-city overflow-y-scroll scrollbar-hide h-full">
          <ul class="mb-20">
            <li v-for="city in cities" :key="city.id" class="items" @click="handleSelectCity(city)">
              <h6>{{ city.name }}</h6>
              <span>{{city.province.name}}</span>
            </li>
          </ul>
        </div>

      </template>

      <div class="list-city overflow-y-scroll scrollbar-hide h-full" id="list-area" v-if="isShowArea">
        <p>نتایج جستجو</p>
        <ul class="mb-20">
          <li v-for="(area , i) in areas" :key="i" class="items" @click="handleArea(area)">
            <h6>{{ area.title }}</h6>
            <span>{{area.neighborhood}}</span>
          </li>
        </ul>
                <a class="btn-custom w-full left-0 bottom-40" v-if="areas.length <= 0 " @click="handleSumbit">
                  ثبت و ادامه
                  <span class="svg-btn-icon2 bg-orange transform rotate-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
                </span>
                </a>
      </div>
      <div class="text-center text-white mt-4" v-else>لطفا اول شهر را خود را انتخاب کنید</div>

    </div>

  </div>

</template>

<script>
import axios from '../../plugins/axios'
import axiosMap from '../../plugins/mapAxios'
export default {
  name: "SelectCity",
  components: {

  },
  data() {
    return {
      cities : null,
      isShowCities : false,
      filter : '',
      isShowArea : false,
      selectedCity : 'انتخاب شهر',
      areas : [],
      textArea : 'منطقه مورد نظر خود را جستجو کنید',
      selectedArea : this.$store.state.filters.filter.area.value,
      timer : null,
      timerCity : null,
    }
  },
  methods:{
    handleSelectCity(data){
      this.selectedCity = data.name;
      this.isShowCities = false;
      this.isShowArea = true
      this.selectedArea = [];
    },
    async handleSumbit(){
      let data = {
        lat : this.cities[0].lat,
        lon : this.cities[0].lng
      }
      await this.$store.commit('map/CLEAR_SELECT_LOCATION', data);
      await this.$store.commit('map/SET_SELECT_LOCATION', data);
      this.$router.push({name : 'RequestAdMap'})
    },
    async handleArea(area){
      let data = {
        lat : area.geom.coordinates[1],
        lon : area.geom.coordinates[0]
      }
      await this.$store.commit('map/CLEAR_SELECT_LOCATION', data);
      await this.$store.commit('map/SET_SELECT_LOCATION', data);
      await this.$router.push({name : 'RequestAdMap'})
    },
    filterCity(e){
      if(this.timerCity){
        clearTimeout(this.timerCity);
        this.timerCity = null
      }
      this.timerCity = setTimeout(async ()=>{
        if (e.target.value !== '') {
          if (this.isShowCities) {
            this.$isLoading(true)
            axios.post('options/cities',{
              filter:{
                name:{
                  operator: "LIKE",
                  value: `%${e.target.value}%`
                }
              }
            }).then(({data})=>{
              this.cities = [];
              this.cities = data.data;
            })
          }
        }
      },800)
    },
    filterArea(e) {
      if(this.timer){
        clearTimeout(this.timer);
        this.timer = null
      }
      this.timer = setTimeout(async ()=>{
        if (e.target.value !== '') {
          if (this.isShowArea) {
            this.$isLoading(true)
            await axiosMap.post('/search/v2/' ,{
              text : e.target.value,
              $filter:`distance eq 50km`,
              lat : this.cities[0].lat,
              lon : this.cities[0].lng
            }).then(async ({data}) => {
              this.$isLoading(false)
              this.areas = data.value
            }).catch(()=>{
              this.$isLoading(false)
            })
          }
        } else if (e.target.value === '') {
          this.areas = []
          this.textArea = "منطقه مورد نظر خود را جستجو کنید"
        }
      },800)


    },
  },

  mounted() {

  }
}
</script>


<style scoped lang="scss">
.search__wrapper{
  input{
    direction: rtl;
    margin-right: 1rem;
  }
  .selectCity{
    white-space: nowrap;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    position: relative;
    top: 3px;
    margin-left: 0.5rem;
    cursor: pointer;
    &:after{
      content: '';
      width: 1px;
      background-color: #e4e4e424;
      height: 58px;
      top: -22px;
      position: absolute;
      left: -13px;
    }
  }

}
#list-area  {
  ul{
    .items{
      &:before{
        top: 5px !important;
      }
    }
  }
}
.btn-custom{
  position: absolute;
}
</style>
